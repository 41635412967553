import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"

class PageTemplate extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage
    const fluid = (StaticPage.featured_media) ? StaticPage.featured_media.localFile.childImageSharp.fluid : null

    return (
      <Layout>
        <SEO
          title={StaticPage.title}
          description={StaticPage.excerpt}
        />

        <div className="page-template">
          {fluid &&
            <div>
              <Img className="feat_img" fluid={fluid} alt={StaticPage.title} />
            </div>
          }

          <div
            dangerouslySetInnerHTML={{ __html: StaticPage.content }}
          />
        </div>
      </Layout >
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      excerpt
      featured_media {
        localFile {
          childImageSharp {
            fluid (maxWidth: 700) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`